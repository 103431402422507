<script setup lang="ts">
import {DateFormatter} from "~/stCommon/datetime/DateFormatter"; // 言語設定

DateFormatter.initTimezone()
</script>

<template>
  <NuxtLoadingIndicator :height="5" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
