<script setup lang="ts">
import {useMyRoutePaths} from "~/composables/route/useMyRoutePaths";

const route = useRoute()
const path = route.path
const { locale, t } = useI18n()
const localePath = useLocalePath()
const routePaths = useMyRoutePaths()

const siteName = t('siteName')

let menuItems = [
  { path: routePaths.photos(), text: t("caption.photoList") },
  { path: routePaths.posts(), text: t("caption.postList") },
  { path: routePaths.areaMaps(), text: t("caption.areaMapList") },
  { path: routePaths.cookiePolicy(), text: t("caption.cookiePolicy") },
]
const lang = locale.value
if (lang === 'en') {
  menuItems.push({ path: '/', text: "日本語" })
} else {
  menuItems.push({ path: routePaths.about(), text: "このサイトについて" })
  menuItems.push({ path: '/en', text: "English" })
}

const navItemClass = (menuItem: Record<string, any>) => {
  let cls = 'nav-link'
  if (menuItem.path === path) {
    cls += ' active'
  }
  return cls
}

const ariaCurrent = (menuItem: Record<string, any>) => {
  return menuItem.path === path ? 'page' : ''
}
</script>

<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" :href="localePath('/')">
        <img src="/favicon.png" class="logo-img" alt="brand-icon" />
        <span class="site-title">{{siteName}}</span>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-for="menuItem in menuItems" class="nav-item">
            <a :class="navItemClass(menuItem)"
               :aria-current="ariaCurrent(menuItem)"
               :href="menuItem.path">{{ menuItem.text }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.logo-img {
  width: 32px;
}
.site-title {
  margin-left: 0.5rem;
  font-size: 1rem;
}
</style>
