import * as qs from 'qs'

export class MyRoutePaths {
  //@ts-ignore
  localePath: LocalePathFunction
  admin: AdminRoutePaths

  //@ts-ignore
  constructor(localePath: LocalePathFunction) {
    this.localePath = localePath
    this.admin = new AdminRoutePaths()
  }

  home() {
    return this.localePath(`/`)
  }

  login() {
    return '/auth/login'
  }

  about() {
    return this.localePath(`/about`)
  }

  cookiePolicy() {
    return this.localePath(`/support/cookie_policy`)
  }

  posts(params? : ListParams) {
    const page = params?.page
    if (page && page !== 1) {
      return this.localePath({
        path: `/p`,
        query: {
          page: page.toString()
        }
      })
    } else {
      return this.localePath('/p')
    }
  }

  post(id: string) {
    return this.localePath(`/p/${id}`)
  }

  postSeriesList(params? : ListParams) {
    const page = params?.page
    if (page && page !== 1) {
      return this.localePath({
        path: `/series`,
        query: {
          page: page.toString()
        }
      })
    } else {
      return this.localePath('/series')
    }
  }

  postSeries(id: string) {
    return this.localePath(`/series/${id}`)
  }

  journals(params? : ListParams) {
    const page = params?.page
    if (page && page !== 1) {
      return this.localePath({
        path: `/journals`,
        query: {
          page: page.toString()
        }
      })
    } else {
      return this.localePath('/journals')
    }
  }

  journal(id: string) {
    return this.localePath(`/journals/${id}`)
  }

  areaMaps() {
    return this.localePath(`/areamaps`)
  }

  areaMap(id: string, { page }: { page?: number }  = {}) {
    const path = this.localePath(`/areamaps/${id}`)

    let query: Record<string, any> = {}
    query.page = page ? page.toString() : undefined
    if (Object.keys(query).length === 0) {
      return path + '?' + qs.stringify(query)
    } else {
      return path
    }
  }

  areaMapPhotos(id: string) {
    return this.localePath(`/areamaps/${id}/photos`)
  }

  place(id: string, { areaMapId }: { areaMapId: string }) {
    return this.localePath(`/areamaps/${areaMapId}/places/${id}`)
  }

  photos(params?: ListParams) {
    const path = this.localePath(`/photos`)
    if (params) {
      return path + '?' + qs.stringify(params)
    } else {
      return path
    }
  }

  photo(id: string) {
    return this.localePath(`/photos/${id}`)
  }
}

class AdminRoutePaths {
  home() {
    return `/admin/`
  }

  areaMaps() {
    return `/admin/areaMaps`
  }

  areaMap(id: string) {
    return `/admin/areaMaps/${id}`
  }

  areaGuides() {
    return `/admin/areaGuides`
  }

  areaGuideNew(params?: AdminAreaGuideNewParams) {
    const path = `/admin/areaGuides/new`
    if (params) {
      return path + '?' + qs.stringify(params)
    } else {
      return path
    }
  }

  areaGuideEdit(id: string) {
    return `/admin/areaGuides/${id}`
  }

  places({ areaMapId, query }: { areaMapId: string, query?: Record<string, any> }) {
    let path = `/admin/areaMaps/${areaMapId}/places`
    if (query) {
      path += `?${qs.stringify(query)}`
    }
    return path
  }

  placeNew({ areaMapId }: { areaMapId: string }) {
    return `/admin/areaMaps/${areaMapId}/places/new`
  }

  placeEdit(placeId: string, { areaMapId }: { areaMapId: string }) {
    return `/admin/areaMaps/${areaMapId}/places/${placeId}`
  }

  journals() {
    return `/admin/journals`
  }

  posts(params?: ListParams) {
    const path =`/admin/posts`
    if (params) {
      return path + '?' + qs.stringify(params)
    } else {
      return path
    }
  }

  post(id: string) {
    return `/admin/posts/${id}`
  }

  postNew(params?: NewWithTranslationParams) {
    const path = `/admin/posts/new`
    if (params) {
      return `${path}?sourceId=${params.sourceId}&lang=${params.lang}`
    } else {
      return path
    }
  }

  postSeriesList(params? : ListParams) {
    const page = params?.page
    if (!page && page !== 1) {
      return `/admin/postSeries`
    } else {
      return `/admin/postSeries?` + qs.stringify({
        page: page,
      })
    }
  }

  postSeriesNew(): string {
    return `/admin/postSeries/new`
  }

  postSeries(id: string) {
    return `/admin/postSeries/${id}`
  }

  photoSets(): string {
    return `/admin/photosSets`
  }

  bucketImages(): string {
    return `/admin/bucketImages`
  }

  bucketImage(id: string): string {
    return `/admin/bucketImages/${encodeURIComponent(id)}`
  }

  bucketImageDisplayMetadataList(): string {
    return `/admin/bucketImages/displayMetadataList`
  }

  selectableTags(): string {
    return `/admin/selectableTags`
  }

  youTubeVideos(params? : ListParams) {
    const path = '/admin/youtube/videos'
    if (params) {
      return path + '?' + qs.stringify(params)
    } else {
      return path
    }
  }

  youTubeVideoNew() {
    return `/admin/youtube/videos/new`
  }

  youTubeVideoEdit(id: string) {
    return `/admin/youtube/videos/${id}`
  }

  youTubeSubtitlesTranslation() {
    return "/admin/youTube/subtitles/translation"
  }

  accessLogs() {
    return '/admin/accessLogs'
  }

  twoFactor() {
    return '/admin/security/two-factor'
  }
}

interface ListParams {
  page?: number
}

interface NewWithTranslationParams {
  sourceId: string
  lang: string
}

interface AdminAreaGuideNewParams {
  areaMapId: string
  lang: string
}

/**
 * ロケールを考慮したパスを生成する
 *
 * 内部で、useLocalePathを使っているので、composableにしている
 */
export const useMyRoutePaths = () => {
  const localePath = useLocalePath()
  return new MyRoutePaths(localePath)
}